import React, { useState } from 'react';
import {
  Button, Container, Grid, TextField, InputAdornment, Card, Typography, Autocomplete,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import {
  Email, Home, Phone,
} from '@mui/icons-material';
import Hidden from '@mui/material/Hidden';
import Code from './hoc.jpg';

export default function Login() {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [homeAddress, setHomeAddress] = useState();

  const genderOptions = [
    {
      label: 'Female',
      value: 'Female',
    },
    {
      label: 'Male',
      value: 'Male',
    },
  ];
  /*
  const handleSubmit = () => {
    createStudent({
      firstName,
      lastName,
      gender,
      age,
      phoneNumber,
      email,
      homeAddress,
    });
  };
  */

  return (
    <>
      <Hidden smDown>
        <Grid item sm={12} align="center">
          <img src={Code} alt="background" style={{ width: '100%' }} />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid style={{ backgroundColor: '#121b2a' }} align="center">
          <Typography
            variant="h1"
            style={{
              color: '#ffffff',
            }}
          >
            Hour Of Code
          </Typography>
        </Grid>
      </Hidden>
      <Container align="center">
        <Card>
          <CardContent align="center">
            <Grid align="center">
              <h2>sign up</h2>
            </Grid>
            <Grid container sm={8} spacing={3}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="First name"
                  variant="outlined"
                  value={firstName}
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Last name"
                  variant="outlined"
                  value={lastName}
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={genderOptions}
                  sx={{ sm: '6' }}
                  getOptionLabel={genderOptions.label}
                  onChange={(event, newValue) => {
                    setGender(newValue);
                  }}
                  value={gender}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Gender"
                      variant="outlined"

                    />
                  )}
                />

              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Age"
                  variant="outlined"
                  value={age}
                  type="number"
                  OnChange={(e) => setAge(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  OnChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Home Address"
                  variant="outlined"
                  value={homeAddress}
                  OnChange={(e) => setHomeAddress(e.target.value)}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  type="number"
                  value={phoneNumber}
                  OnChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                // onClick={handleSubmit()}
                >
                  submit
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
