import React from 'react';
import HourOfCode from '../../components/HourOfCode/HourOfCode';
import Layout from '../../components/layout';
import ThinkZambiaFooter from '../../components/ThinkZambiaFooter';
import { ThinkZambiaProvider } from '../../context';

export default function HourOfCodePage() {
  return (
    <ThinkZambiaProvider>
      <Layout disableFooter>
        <div style={{
          backgroundColor: 'white',
        }}
        >
          <HourOfCode />
        </div>
        <ThinkZambiaFooter />
      </Layout>
    </ThinkZambiaProvider>
  );
}
